import './App.scss';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <span class="material-icons frontpage-icon">code</span>
        <h1>AJ RAVI</h1>
        <p>Welcome to my personal website</p>
      </header>
    </div>
  );
}

export default App;